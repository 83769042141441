// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
// ** UseJWT import to get config
import useJwt from "@src/auth/jwt/useJwt";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const config = useJwt.jwtConfig;

// Helper function to delete IndexedDB database
const deleteIndexedDB = () => {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.deleteDatabase('ChatAppDB');
    request.onsuccess = () => {
      console.log('IndexedDB deleted successfully');
      resolve();
    };
    request.onerror = () => {
      console.error('Error deleting IndexedDB');
      resolve(); // Resolve anyway to continue logout
    };
  });
};

const initialUser = () => {
  const item = window.localStorage.getItem("userData");
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {};
};

export const authSlice = createSlice({
  name: "authentication",
  initialState: {
    userData: initialUser(),
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload;
      state[config.storageTokenKeyName] =
        action.payload[config.storageTokenKeyName];
      state[config.storageRefreshTokenKeyName] =
        action.payload[config.storageRefreshTokenKeyName];
      localStorage.setItem("userData", JSON.stringify(action?.payload));
      localStorage.setItem(
        config.storageTokenKeyName,
        JSON.stringify(action?.payload.accessToken)
      );
      localStorage.setItem(
        config.storageRefreshTokenKeyName,
        JSON.stringify(action?.payload.refreshToken)
      );
    },
    handleLogout: (state) => {
      state.userData = {};
      state[config.storageTokenKeyName] = null;
      state[config.storageRefreshTokenKeyName] = null;
      
      // Delete IndexedDB before clearing storage
      deleteIndexedDB().then(() => {
        // Clear all storage
        localStorage.clear();
        sessionStorage.clear();

        if (window.location.hostname === 'dashboard.cmsmart.net') {
          window.location.href = 'https://cmsmart.net/logout';
        } else {
          window.location.href = '/login';
        }
      });
    },
  },
});

export const { handleLogin, handleLogout } = authSlice.actions;

export default authSlice.reducer;
